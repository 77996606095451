<!-- sss -->
<template>
  <div class="page-wrapper h100">
    <div class="error-404">
      <img :src="url" />
      <div class="error-title mt20">404</div>
      <div class="mt20">
        <el-button type="primary" @click="handleToHome">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg",
    };
  },

  methods: {
    handleToHome() {
      this.$router.push({ path: "/standerDev/appList" });
    },
  },
};
</script>
<style lang="scss" scoped>
.error-404 {
  margin-top: 150px;
  min-height: 500px;
  height: 80%;
  align-items: center;
  text-align: center;
  .error-title {
    font-size: 30px;
    color: #909399;
  }
}
</style>
